// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .robotImage {
    width: 150px;
    height: 150px;
    top: -120px !important;
  }
}
@media (max-width: 580px) {
  .heroTitle {
    font-size: 34px !important;
    line-height: 48px !important;
  }
  .imagesWrapper {
    margin-top: 160px !important;
  }
  .headerLinksContainer {
    flex-direction: column;
    gap: 16px !important;
  }
  .headerMain {
    height: 160px !important;
  }
  .footerLinks {
    gap: 24px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;AACxB;AACA;EACE;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;AACF;AACA;EACE;IACE,0BAA0B;IAC1B,4BAA4B;EAC9B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,sBAAsB;IACtB,oBAAoB;EACtB;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,oBAAoB;EACtB;AACF","sourcesContent":["body {\n  margin: 0;\n  box-sizing: border-box;\n}\n@media (max-width: 1023px) {\n  .robotImage {\n    width: 150px;\n    height: 150px;\n    top: -120px !important;\n  }\n}\n@media (max-width: 580px) {\n  .heroTitle {\n    font-size: 34px !important;\n    line-height: 48px !important;\n  }\n  .imagesWrapper {\n    margin-top: 160px !important;\n  }\n  .headerLinksContainer {\n    flex-direction: column;\n    gap: 16px !important;\n  }\n  .headerMain {\n    height: 160px !important;\n  }\n  .footerLinks {\n    gap: 24px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
