import React from "react";

/**
 * MailchimpSubscribe component for embedding a Mailchimp subscription form.
 *
 * @returns {JSX.Element} The rendered Mailchimp subscription form.
 */
const MailchimpSubscribe = () => {
  return (
    <div id="mc_embed_shell">
      {/* Wrapper for the Mailchimp embed code */}
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" // External stylesheet for Mailchimp form styling
        rel="stylesheet"
        type="text/css"
      />
      <style type="text/css">
        {/* Inline styles for the subscription form */}
        {`
          #mc_embed_signup {
            background: #fff; // White background for the form
            clear: left; // Clear floating elements
            font: 14px Helvetica, Arial, sans-serif; // Font styling
            width: 600px; // Set width of the form
          }
        `}
      </style>
      <div id="mc_embed_signup">
        {/* Container for the subscription form */}
        <header>
          <div className="headerMain" style={{ height: "104px", padding: "0 20px", maxWidth: "936px", fontStyle: "italic", margin: "0 auto", display: "flex", alignItems: "center", justifyContent: "space-between", fontFamily: "Arial, sans-serif" }}>
            <div style={{ fontFamily: "Poppins", fontWeight: "900", lineHeight: "24px", fontSize: "24px" }}>NOW</div>

            <div className="headerLinksContainer" style={{ display: "flex", alignItems: "center", gap: "36px" }}>
              <a target="_blank" href="https://forms.gle/AGmMdHb7F5K7Xyz76" style={{ textDecoration: "unset", display: "flex", alignItems: "center", gap: "12px" }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 12C14 12.8284 14.6716 13.5 15.5 13.5C16.3284 13.5 17 12.8284 17 12C17 11.1716 16.3284 10.5 15.5 10.5C14.6716 10.5 14 11.1716 14 12ZM14 12H2.5M2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12ZM10.8104 16.25C10.8104 15.4216 10.1388 14.75 9.31039 14.75C8.48196 14.75 7.81039 15.4216 7.81039 16.25C7.81039 17.0784 8.48196 17.75 9.31039 17.75C10.1388 17.75 10.8104 17.0784 10.8104 16.25ZM10.8104 16.25H20.4981M10.8104 7.75C10.8104 8.57843 10.1388 9.25 9.31039 9.25C8.48196 9.25 7.81039 8.57843 7.81039 7.75C7.81039 6.92157 8.48196 6.25 9.31039 6.25C10.1388 6.25 10.8104 6.92157 10.8104 7.75ZM10.8104 7.75H20.4981" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <span style={{ fontFamily: "Inter", fontWeight: "500", lineHeight: "24px", fontSize: "16px", color: "#0A0A0B" }}>Become a Merchant</span>
              </a>

              <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.49609 9.24771L2.99672 7.74608L4.49734 9.24671M22.5048 10.7483L21.0042 12.249L19.5036 10.7483M4.67542 4.78485C7.15316 1.27416 11.7687 0.00749171 15.6913 1.7617C19.614 3.51591 21.7469 7.80058 20.7821 11.9878M7.66866 12.5011C7.0421 11.4161 7.36098 10.0314 8.39897 9.32974C9.2314 8.76576 10.3281 8.78416 11.1411 9.37576L12.8568 10.6253C13.6699 11.2169 14.7665 11.2353 15.599 10.6713C16.6365 9.96937 16.9553 8.58501 16.3293 7.49998M19.3215 15.2022C16.8438 18.7129 12.2282 19.9795 8.30559 18.2253C4.38298 16.4711 2.24999 12.1865 3.21481 7.99919M17.0026 10C17.0026 12.7626 14.763 15.0021 12.0005 15.0021C9.23789 15.0021 6.99839 12.7626 6.99839 10C6.99839 7.23744 9.23789 4.99794 12.0005 4.99794C14.763 4.99794 17.0026 7.23744 17.0026 10Z" stroke="#9d9d9d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span style={{ fontFamily: "Inter", fontWeight: "500", lineHeight: "24px", fontSize: "14px", color: "#6C7275" }}>Arabic | اللغة الانجليزية</span>
              </div>

              <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 3.25C14.4142 3.25 14.75 3.58579 14.75 4V4.25H15C15.4142 4.25 15.75 4.58579 15.75 5C15.75 5.41421 15.4142 5.75 15 5.75H14.75V6C14.75 6.41421 14.4142 6.75 14 6.75C13.5858 6.75 13.25 6.41421 13.25 6V5.75H13C12.5858 5.75 12.25 5.41421 12.25 5C12.25 4.58579 12.5858 4.25 13 4.25H13.25V4C13.25 3.58579 13.5858 3.25 14 3.25ZM19.75 7C19.75 6.58579 19.4142 6.25 19 6.25C18.5858 6.25 18.25 6.58579 18.25 7V8.25H17C16.5858 8.25 16.25 8.58579 16.25 9C16.25 9.41421 16.5858 9.75 17 9.75H18.25V11C18.25 11.4142 18.5858 11.75 19 11.75C19.4142 11.75 19.75 11.4142 19.75 11V9.75H21C21.4142 9.75 21.75 9.41421 21.75 9C21.75 8.58579 21.4142 8.25 21 8.25H19.75V7ZM9.9103 5.43594C10.0812 5.19662 10.0965 4.87947 9.94941 4.62482C9.80228 4.37016 9.51989 4.225 9.22718 4.25356C4.75318 4.69004 1.25 8.40385 1.25 13C1.25 17.8142 5.18579 21.75 10 21.75C13.6205 21.75 16.7836 19.4511 18.093 16.2868C18.2047 16.017 18.1496 15.7069 17.9518 15.492C17.754 15.2772 17.4495 15.1967 17.1714 15.2857C14.9076 16.0101 12.3096 15.5489 10.5303 13.7697C8.30336 11.5427 8.11187 7.95375 9.9103 5.43594ZM2.75 13C2.75 9.73297 4.88691 6.99531 7.85074 6.05739C6.61149 8.95437 7.1198 12.4805 9.46967 14.8303C11.2534 16.6141 13.6702 17.3127 15.9702 17.0649C14.6509 18.9746 12.4404 20.25 10 20.25C6.01421 20.25 2.75 16.9858 2.75 13Z" fill="#0A0A0B" />
                </svg>

              </div>
            </div>
          </div>

        </header>
        <body>
          <section style={{ padding: "0 20px", maxWidth: "936px", margin: "0 auto" }}>
            <form
              action="https://almeyar.us13.list-manage.com/subscribe/post?u=6b07beb21e6183adcd200bfd6&amp;id=1bdf008abd&amp;f_id=00b3a0e0f0" // Mailchimp form action URL
              method="post" // Form submission method
              id="mc-embedded-subscribe-form" // Unique identifier for the form
              name="mc-embedded-subscribe-form" // Name for the form
              className="validate" // CSS class for validation styles
              target="_blank" // Open submission response in a new tab
              style={{ margin: "0" }}
            >
              {/*---------------------- hero Banner ------------------------*/}
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", }}>
                <h1 className="heroTitle" style={{ fontFamily: "Inter", fontWeight: "700", lineHeight: "57.6px", fontSize: "48px", color: "#F4B31B", maxWidth: "640px", margin: "40px 0 20px" }}>
                  Be the First to Unlock the Power of NOW!!
                </h1>

                <p style={{ fontFamily: "Inter", fontWeight: "400", lineHeight: "29.6px", fontSize: "20px", color: "#5A656F", maxWidth: "560px", margin: "0px 0 20px" }}>
                  Be among the first to get the best mouth-watering deals of the services you need, from merchants you trust.
                </p>

                <div style={{ display: "flex", gap: "8px", alignItems: "center", flexWrap: "wrap" }}>
                  <div style={{ display: "flex", height: "56px", alignItems: "center", border: "1px solid #E0E0E0", borderRadius: "20px", overflow: "hidden" }}>
                    <svg style={{ padding: "16px 12px 16px 22px", fontSize: "16px", color: "#999" }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.105 16.082C12.416 16.367 11.702 16.5 11 16.5C7.963 16.5 5.5 14.037 5.5 11C5.5 7.963 7.963 5.5 11 5.5C14.037 5.5 16.5 7.963 16.5 11V11.916C16.5 12.865 15.73 13.634 14.782 13.634C13.834 13.634 13.064 12.864 13.064 11.916V11M13.064 11C13.064 9.86 12.141 8.938 11.002 8.938C9.862 8.938 8.94 9.861 8.94 11C8.94 12.139 9.863 13.062 11.002 13.062C12.141 13.062 13.064 12.14 13.064 11ZM11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <input
                      type="email" // Email input type
                      name="EMAIL" // Name attribute for the email input
                      className="required email" // Required class for validation
                      id="mce-EMAIL" // Unique identifier for the email input
                      required // Mark input as required
                      placeholder="Enter your email"
                      style={{ border: "none", outline: "none", padding: "10px", fontFamily: "Inter", fontWeight: "400", lineHeight: "23.68px", fontSize: "16px", color: "#697783", flex: "1", minWidth: "200px" }}
                    />
                  </div>
                  <input
                    type="submit" // Submit button type
                    name="subscribe" // Name attribute for the submit button
                    value="Join Waitlist" // Text on the submit button
                    placeholder="Join Waitlist"
                    style={{ borderRadius: "20px", height: "56px", backgroundColor: "#FFD046", fontFamily: "Inter", fontWeight: "600", lineHeight: "24px", fontSize: "16px", color: "#000000", padding: "10px 20px", border: "none", cursor: "pointer" }}
                  />
                </div>
              </div>

              {/* ---------------------------Middle image ---------------------------*/}
              <div className="imagesWrapper" style={{ position: "relative", margin: "84px 0 0" }}>
                <div><img style={{ position: "relative", zIndex: "10", width: "100%", height: "100%", maxWidth: "896px" }} src="https://i.ibb.co.com/dDsgpZh/Feature-image.png" alt="Feature-image" /></div>
                <img className="robotImage" style={{ position: "absolute", right: "-16px", top: "-247px" }} src="https://i.ibb.co.com/Dz45CZV/robot-Image.png" alt="robot-Image" />
              </div>


            </form>
          </section>
        </body>
        <footer>
          <div style={{ padding: "80px 0 24px", }}>
            <div style={{ padding: "0 20px", maxWidth: "936px", margin: "0 auto 104px auto", }}>

              <div style={{ maxWidth: "600px", display: "flex", flexWrap: "wrap", gap: "40px", justifyContent: "space-between", alignItems: "flex-start", }}>
                {/* Logo */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src="https://i.ibb.co.com/SBJCCDZ/footer-logo.png" alt="Logo" style={{ marginLeft: "-20px", marginTop: "-12px", width: "145px", height: "102px", borderRadius: "10px" }} />
                </div>

                {/* Links */}
                <div className="footerLinks" style={{ display: "flex", gap: "84px", flexWrap: "wrap" }}>
                  <div style={{ minWidth: "120px", textAlign: "left" }}>
                    <h4 style={{ fontFamily: "Inter", fontWeight: "600", lineHeight: "20px", fontSize: "14px", color: "#0A0A0B", marginTop: "0", marginBottom: "24px" }}>COMPANY</h4>
                    <a href="#" style={{ display: "block", textDecoration: "unset", fontFamily: "Inter", fontWeight: "400", lineHeight: "23.68px", fontSize: "16px", color: "#3A4248", marginBottom: "16px" }}>About Us</a>
                    <a href="#" style={{ display: "block", textDecoration: "unset", fontFamily: "Inter", fontWeight: "400", lineHeight: "23.68px", fontSize: "16px", color: "#3A4248", marginBottom: "16px" }}>Become a Merchant</a>
                  </div>
                  <div style={{ minWidth: "120px", textAlign: "left" }}>
                    <h4 style={{ fontFamily: "Inter", fontWeight: "600", lineHeight: "20px", fontSize: "14px", color: "#0A0A0B", marginTop: "0", marginBottom: "24px" }}>GUIDES</h4>
                    <a href="#" style={{ display: "block", textDecoration: "unset", fontFamily: "Inter", fontWeight: "400", lineHeight: "23.68px", fontSize: "16px", color: "#3A4248", marginBottom: "16px" }}>Privacy</a>
                    <a href="#" style={{ display: "block", textDecoration: "unset", fontFamily: "Inter", fontWeight: "400", lineHeight: "23.68px", fontSize: "16px", color: "#3A4248", marginBottom: "16px" }}>Terms</a>
                    <a href="#" style={{ display: "block", textDecoration: "unset", fontFamily: "Inter", fontWeight: "400", lineHeight: "23.68px", fontSize: "16px", color: "#3A4248", marginBottom: "16px" }}>Contact</a>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer Bottom */}
            <div style={{ borderTop: "1px solid #E1E4E7", paddingTop: "24px" }}>
              <div style={{ padding: "0 20px", margin: "0 auto", maxWidth: "936px", display: "flex", flexWrap: "wrap", gap: "30px", justifyContent: "space-between", alignItems: "center", fontSize: "14px", color: "#888" }}>
                <p style={{ margin: "0", fontFamily: "Inter", fontWeight: "400", lineHeight: "23.68px", fontSize: "16px", color: "#697783", }}>© 2024 شركة ابتكار الخدمات لتقنية المعلومات. All rights reserved.</p>
                <div style={{ display: "flex", gap: "24px" }}>
                  <a href="#">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.12 11.1V16.5M11.719 16.5V13.35C11.719 12.107 12.726 11.1 13.969 11.1C15.212 11.1 16.219 12.107 16.219 13.35V16.5M7.5 3H16.504C18.987 3 21 5.013 21 7.496V16.505C21 18.987 18.987 21 16.504 21H7.496C5.013 21 3 18.987 3 16.504V7.5C3 5.015 5.015 3 7.5 3ZM8.118 7.838C7.994 7.838 7.893 7.939 7.894 8.063C7.894 8.187 7.995 8.288 8.119 8.288C8.243 8.288 8.344 8.187 8.344 8.063C8.344 7.938 8.243 7.838 8.118 7.838Z" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>

                  <a href="#">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.1 12.9H16.5M16.5 8.4H15.555C14.089 8.4 12.9 9.589 12.9 11.055V21M7.5 3H16.504C18.987 3 21 5.013 21 7.496V16.505C21 18.987 18.987 21 16.504 21H7.496C5.013 21 3 18.987 3 16.504V7.5C3 5.015 5.015 3 7.5 3Z" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>

                  <a href="#">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2 9.3501C19.2 15.6501 15.6 20.1501 9.3 20.1501C5.7 20.1501 4.257 18.2711 3 16.5501M3 16.5501C3.029 16.5451 5.7 15.6501 5.7 15.6501C2.694 12.6111 2.466 8.1101 4.8 4.8501C5.907 6.9091 7.974 8.8091 10.2 9.3501C10.286 6.7501 12.049 4.8501 14.7 4.8501C16.505 4.8501 17.567 5.5381 18.3 6.6501H21L19.2 9.3501" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>

                </div>
              </div>
            </div>
          </div>

        </footer>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js" // Mailchimp validation script
      ></script>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0]='EMAIL';
            ftypes[0]='email';
            fnames[1]='FNAME';
            ftypes[1]='text';
            fnames[2]='LNAME';
            ftypes[2]='text';
            fnames[3]='ADDRESS';
            ftypes[3]='address';
            fnames[4]='PHONE';
            ftypes[4]='phone';
            fnames[5]='BIRTHDAY';
            ftypes[5]='birthday';
            fnames[6]='COMPANY';
            ftypes[6]='text';
          }(jQuery)); var $mcj = jQuery.noConflict(true);`, // Inline script for Mailchimp integration
        }}
      ></script>
    </div>
  );
};

export default MailchimpSubscribe; // Export the MailchimpSubscribe component
