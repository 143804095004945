import "./App.css";
import MailchimpSubscribe from "./components/MailchimpSubscribe/MailchimpSubscribe";

function App() {
  return (
    <div className="App">
      {/* Main application container */}
      <MailchimpSubscribe />
    </div>
  );
}

export default App;
